{
  "openInNewWindow": "In eigenem Fenster öffnen",
  "2": "2",
  "UI Elements": "UI-Elemente",
  "Forms & Tables": "Formulare und Tabellen",
  "Pages": "Seiten",
  "Charts & Maps": "Charts & Karten",
  "Others": "Andere",
  "Typography": "Typografie",
  "Cards": "Karten",
  "Basic": "Grundlegend",
  "Advance": "Vorschuss",
  "Analytic": "Analytisch",
  "Components": "Komponenten",
  "Alert": "Alarmierung",
  "Avatar": "Avatar",
  "Badge": "Abzeichen",
  "Button": "Schaltfläche",
  "Calendar": "Kalender",
  "Image": "Bild",
  "Pagination": "Paginierung",
  "Progress Circular": "Progress-Rundschreiben",
  "Progress Linear": "Fortschritt Linear",
  "Autocomplete": "Autovervollständigen",
  "Tooltip": "QuickInfo",
  "Slider": "Schieberegler",
  "Date Time Picker": "Datum-Zeit-Auswahl",
  "Select": "Wählen Sie",
  "Switch": "Schalter",
  "Checkbox": "Kontrollkästchen",
  "Radio": "Radio",
  "Textarea": "Textarea",
  "Rating": "Bewertung",
  "File Input": "Datei-Eingabe",
  "Form Layout": "Formular Layout",
  "Form Validation": "Validierung von Formularen",
  "Charts": "Diagramme",
  "Apex Chart": "Apex-Diagramm",
  "Chartjs": "Chartjs",
  "Account Settings": "Kontoeinstellungen",
  "User Profile": "Benutzerprofil",
  "FAQ": "FAQ",
  "Dialog Examples": "Dialog-Beispiele",
  "Pricing": "Preisgestaltung",
  "List": "Liste",
  "Edit": "bearbeiten",
  "Nav Levels": "Navigationsebenen",
  "Level 2.1": "Ebene 2.1",
  "Level 2.2": "Ebene 2.2",
  "Level 3.1": "Ebene 3.1",
  "Level 3.2": "Stufe 3.2",
  "Raise Support": "Unterstützung anheben",
  "Documentation": "Dokumentation",
  "Dashboards": "Dashboards",
  "Analytics": "Analytik",
  "Apps & Pages": "Apps & Seiten",
  "Password": "Passwort",
  "Remember me": "Eingeloggt bleiben",
  "Chat": "Chat",
  "Invoice": "Rechnung",
  "Preview": "Vorschau",
  "Add": "hinzufügen",
  "User": "Benutzer",
  "View": "Siehe",
  "Login v1": "Anmeldung v1",
  "Login v2": "Anmeldung v2",
  "Login": "Anmelden",
  "Register v1": "Register v1",
  "Register v2": "Register v2",
  "Register": "Register",
  "Forget Password v1": "Passwort vergessen v1",
  "Forget Password v2": "Passwort vergessen v2",
  "Forgot Password v1": "Passwort vergessen v1",
  "Forgot Password v2": "Passwort vergessen v2",
  "Forgot Password": "Passwort vergessen",
  "Reset Password v1": "Passwort zurücksetzen v1",
  "Reset Password v2": "Passwort zurücksetzen v2",
  "Reset Password": "Passwort zurücksetzen",
  "Miscellaneous": "Sonstiges",
  "Coming Soon": "Demnächst verfügbar",
  "Not Authorized": "Nicht autorisiert",
  "Under Maintenance": "Unter Wartung",
  "Error": "Fehler",
  "Statistics": "Statistik",
  "Actions": "Aktionen",
  "Access Control": "Zugangskontrolle",
  "User Interface": "Benutzeroberfläche",
  "CRM": "CRM",
  "Icons": "Icons",
  "Chip": "Chip",
  "Dialog": "Dialog",
  "Expansion Panel": "Erweiterungspanel",
  "Combobox": "Combobox",
  "Textfield": "Textfeld",
  "Range Slider": "Schieberegler",
  "Menu": "Menü",
  "Snackbar": "Imbissstube",
  "Tabs": "Registerkarten",
  "Form Elements": "Formular-Elemente",
  "Form Layouts": "Formular Layouts",
  "Authentication": "Authentifizierung",
  "Page Not Found - 404": "Seite nicht gefunden - 404",
  "Not Authorized - 401": "Nicht autorisiert - 401",
  "Server Error - 500": "Serverfehler - 500",
  "Gamification": "Gamifizierung",
  "Forms": "Formulare",
  "Timeline": "Zeitleiste",
  "Widgets": "Widgets",
  "eCommerce": "eCommerce",
  "New": "Neu",
  "Disabled Menu": "Deaktiviertes Menü",
  "Help Center": "Hilfe-Center",
  "Verify Email": "E-Mail überprüfen",
  "Verify Email v1": "E-Mail v1 überprüfen",
  "Verify Email v2": "E-Mail überprüfen v2",
  "Two Steps": "Zwei Schritte",
  "Two Steps v1": "Two Steps v1",
  "Two Steps v2": "Two Steps v2",
  "Custom Input": "Benutzerdefinierte Eingabe",
  "Extensions": "Erweiterungen",
  "Tour": "Rundgang",
  "Register Multi-Steps": "Mehrstufige Registrierung",
  "Wizard Examples": "Zauberhafte Beispiele",
  "Checkout": "Kasse",
  "Create Deal": "Deal erstellen",
  "Property Listing": "Immobilienverzeichnis",
  "Roles & Permissions": "Rollen und Berechtigungen",
  "Roles": "Rollen",
  "Simple Table": "Einfacher Tisch",
  "Tables": "Tische",
  "Data Table": "Datentabelle",
  "Permissions": "Erlaubnisse",
  "Apps": "Apps",
  "Misc": "Verschiedenes",
  "Wizard Pages": "Zauberhafte Seiten",
  "Form Wizard": "Formular-Assistent",
  "Numbered": "Nummeriert",
  "Details": "Details",
  "Email": "E-Mail",
  "Phone": "Tel.",
  "Mobile": "Mobil",
  "ZIP": "PLZ",
  "City": "Ort",
  "Age": "Alter",
  "Years": "Jahre",
  "Citizenship": "Staatsbürgerschaft",
  "Gender": "Geschlecht",
  "gender": {
    "male": "Männlich",
    "female": "Weiblich"
  },
  "Search": "Suche",
  "$vuetify": {
    "badge": "Badge",
    "pagination": {
      "ariaLabel": {
        "root": "root",
        "previous": "previous",
        "next": "next",
        "currentPage": "currentPage",
        "page": "page"
      }
    },
    "input": {
      "clear": "clear",
      "appendAction": "appendAction",
      "prependAction": "prependAction",
      "counterSize": "counterSize"
    },
    "fileInput": {
      "counterSize": "counterSize"
    },
    "rating": {
      "ariaLabel": {
        "item": "item"
      }
    }
  },
  "approve": "genehmigen",
  "edit": "bearbeiten",
  "Home": "Startseite",
  "Technologies": "Technologien",
  "Profile": "Profil",
  "Settings": "Einstellungen",
  "Applicants": "Bewerber",
  "Overview": "Übersicht",
  "Career": "Karriere",
  "Documents": "Dokumente",
  "Internal": "Intern",
  "Logout": "Ausloggen",
  "or": "oder",
  "cancel": "Abbrechen",
  "ok": "OK",
  "close": "Schließen",
  "save": "Speichern",
  "save_and_approve": "Speichern und bestätigen",
  "back-home": "Zurück zur Startseite",
  "table-header": {
    "id": "ID",
    "title": "TITEL",
    "actions": "AKTIONEN"
  },
  "delete-item": {
    "title": "Sind Sie sicher, dass Sie dieses Element löschen möchten?"
  },
  "edit-item": {
    "title": "Element bearbeiten"
  },
  "pages": {
    "login": {
      "welcome": "Willkommen zu {title}! 👋🏻",
      "signin-text": "Bitte melden Sie sich bei Ihrem Konto an und starten Sie das Abenteuer",
      "new-on-platform": "Neu auf unserer Plattform?",
      "create-account": "Ein Konto erstellen"
    },
    "not-authorized": {
      "title": "Sie sind nicht berechtigt! 🔐",
      "text": "Sie haben keine Berechtigung zum Zugriff auf diese Seite."
    },
    "getting-logged-in": {
      "title": "Ihr Anmeldevorgang ist im Gange.",
      "login-error": {
        "title": "Anmeldung fehlgeschlagen",
        "text": "Bitte überprüfen Sie Ihre Anmeldedaten und versuchen Sie es erneut. Wenn Sie weiterhin Probleme haben, wenden Sie sich bitte an den Kundendienst, um Hilfe zu erhalten."
      }
    },
    "applicants": {
      "itemsPerPage": "Bewerber pro Seite",
      "language": "Sprache",
      "language-level": "Sprach-Level",
      "filter": {
        "hide": "Filter ausblenden",
        "show": "Filter einblenden"
      },
      "swx_sperrliste_experte": "Sperrliste Experte: {value}",
      "swx_sperrliste_kunden": "Sperrliste Kunden: {value}",
      "swx_arbeitet_fuer_swx": "Arbeitet für softwareXperts",
      "notes_last_updated": "Notizen zuletzt aktualisiert: {date}",
      "person": {
        "location": "Einsatzort",
        "field_of_activity": "Tätigkeitsfeld",
        "applicant_classification": "Bewerber - Klassifizierung",
        "availability": "Verfügbarkeit"
      }
    },
    "applicant_id": {
        "cv-documents": "Lebenslauf Dokumente",
        "other-documents": "Sonstige Dokumente",
        "no-documents": "Keine Dokumente vorhanden"
    },
    "projects": {
      "page-title": "Arbeitstitel: {title}",
      "project-information": "Projektinformationen",
      "project-technologies": {
        "title": "Technologien",
        "must-have": "Must Have",
        "nice-to-have": "Nice To Have",
      },
      "project-requirements": {
        "de": "Anforderungen (de)",
        "en": "Anforderungen (en)",
      },
      "project-tasks": {
        "de": "Aufgaben (de)",
        "en": "Aufgaben (en)",
      },
      "actions": {
        "label": "Aktionen",
        "addToProject": "Zum Projekt hinzufügen",
      }
    }
  },
  "error-page": {
    "title": "Seite nicht gefunden ⚠️",
    "text": "Wir konnten die gesuchte Seite nicht finden."
  },
  "roles": {
    "User": "Bewerber",
    "Admin": "Admin",
    "SWX Admin": "SWX Admin",
    "SWX (Pending)": "SWX (ausstehend)"
  },
  "cv-rows": {
    "title": "\"{task}\" bei \"{location}\"",
    "task": "Was? (Position / Abschluss)",
    "location": "Wo? (Arbeitgeber / Schule)",
    "description": "Beschreibung / Sonstiges",
    "delete": {
      "title": "\"{task}\" bei \"{location}\" löschen?",
      "description": "Soll \"{task}\" bei \"{location}\" wirklich gelöscht werden?",
      "confirm": "Ja, löschen",
      "cancel": "Abbrechen"
    },
    "create": "Erstellen",
    "add": "Hinzufügen",
    "add_ai": "Hinzufügen (KI)",
    "raw_input": "Roheingabe",
    "row_type": "Typ",
    "newTechnologies": "Neue Technologien (kommagetrennt)",
    "from": "Von",
    "to": "Bis",
    "since": "Seit",
    "in_year": "Im Jahr",
    "in_month": "Im Monat",
    "no-valid-date": "Kein Korrektes Datum oder Datumstyp",
    "technologies": {
      "matched": "Vordefinierte Technologien",
      "individual": "Individuelle Technologien"
    },
    "date_type": {
      "title": "Datumsformat",
      "since": "seit",
      "fromto": "Von - Bis",
      "in": "in Jahr/im Monat, Jahr"
    },
    "timespan": {
      "title": "Wann?",
      "since": "seit {date}",
      "fromto": "{from} - {to}",
      "in_month": "im {month} {year}",
      "in_year": "in {year}"
    }
  },
  "success": {
    "cv_row": {
      "update": "Die Zeile wurde erfolgreich aktualisiert.",
      "create": "Die Zeile wurde erfolgreich erstellt.",
      "delete": "Die Zeile wurde erfolgreich gelöscht.",
      "add_technology": "{technology} wurde hinzugefügt."
    }
  },
  "errors": {
    "fill_all_fields": "Bitte füllen Sie alle Felder aus.",
    "cv_row": {
      "update": "Die Zeile konnte nicht aktualisiert werden.",
      "create": "Die Zeile konnte nicht erstellt werden.",
      "delete": "Die Zeile konnte nicht gelöscht werden.",
      "add_technology": {
        "exists": "{technology} existiert bereits."
      }
    }
  }
}
