{
  "UI Elements": "UI Elements",
  "Forms & Tables": "Forms & Tables",
  "Pages": "Pages",
  "Charts & Maps": "Charts & Maps",
  "Others": "Others",
  "Typography": "Typography",
  "Cards": "Cards",
  "Basic": "Basic",
  "Advance": "Advance",
  "Analytic": "Analytic",
  "Components": "Components",
  "Alert": "Alert",
  "Avatar": "Avatar",
  "Badge": "Badge",
  "Button": "Button",
  "Calendar": "Calendar",
  "Image": "Image",
  "Pagination": "Pagination",
  "Progress Circular": "Progress Circular",
  "Progress Linear": "Progress Linear",
  "Autocomplete": "Autocomplete",
  "Tooltip": "Tooltip",
  "Slider": "Slider",
  "Date Time Picker": "Date Time Picker",
  "Select": "Select",
  "Switch": "Switch",
  "Checkbox": "Checkbox",
  "Radio": "Radio",
  "Textarea": "Textarea",
  "Rating": "Rating",
  "File Input": "File Input",
  "Form Layout": "Form Layout",
  "Form Validation": "Form Validation",
  "Charts": "Charts",
  "Apex Chart": "Apex Chart",
  "Chartjs": "Chartjs",
  "Account Settings": "Account Settings",
  "User Profile": "User Profile",
  "FAQ": "FAQ",
  "Dialog Examples": "Dialog Examples",
  "Pricing": "Pricing",
  "List": "List",
  "Edit": "Edit",
  "Nav Levels": "Nav Levels",
  "Level 2.1": "Level 2.1",
  "Level 2.2": "Level 2.2",
  "Level 3.1": "Level 3.1",
  "Level 3.2": "Level 3.2",
  "Raise Support": "Raise Support",
  "Documentation": "Documentation",
  "Dashboards": "Dashboards",
  "Analytics": "Analytics",
  "Apps & Pages": "Apps & Pages",
  "Email": "Email",
  "Password": "Password",
  "Remember me": "Remember me",
  "Chat": "Chat",
  "Invoice": "Invoice",
  "Preview": "Preview",
  "Add": "Add",
  "User": "User",
  "View": "View",
  "Login v1": "Login v1",
  "Login v2": "Login v2",
  "Login": "Login",
  "Register v1": "Register v1",
  "Register v2": "Register v2",
  "Register": "Register",
  "Overview": "Overview",
  "Forget Password v1": "Forget Password v1",
  "Forget Password v2": "Forget Password v2",
  "Forgot Password v1": "Forgot Password v1",
  "Forgot Password v2": "Forgot Password v2",
  "Forgot Password": "Forgot Password",
  "Reset Password v1": "Reset Password v1",
  "Reset Password v2": "Reset Password v2",
  "Reset Password": "Reset Password",
  "Miscellaneous": "Miscellaneous",
  "Coming Soon": "Coming Soon",
  "Not Authorized": "Not Authorized",
  "Under Maintenance": "Under Maintenance",
  "Error": "Error",
  "Statistics": "Statistics",
  "Actions": "Actions",
  "Access Control": "Access Control",
  "User Interface": "User Interface",
  "CRM": "CRM",
  "Icons": "Icons",
  "Chip": "Chip",
  "Dialog": "Dialog",
  "Expansion Panel": "Expansion Panel",
  "Combobox": "Combobox",
  "Textfield": "Textfield",
  "Range Slider": "Range Slider",
  "Menu": "Menu",
  "Snackbar": "Snackbar",
  "Tabs": "Tabs",
  "Form Elements": "Form Elements",
  "Form Layouts": "Form Layouts",
  "Authentication": "Authentication",
  "Page Not Found - 404": "Page Not Found - 404",
  "Not Authorized - 401": "Not Authorized - 401",
  "Server Error - 500": "Server Error - 500",
  "2": "2",
  "Gamification": "Gamification",
  "Forms": "Forms",
  "Timeline": "Timeline",
  "Widgets": "Widgets",
  "eCommerce": "eCommerce",
  "New": "New",
  "Disabled Menu": "Disabled Menu",
  "Help Center": "Help Center",
  "Verify Email": "Verify Email",
  "Verify Email v1": "Verify Email v1",
  "Verify Email v2": "Verify Email v2",
  "Two Steps": "Two Steps",
  "Two Steps v1": "Two Steps v1",
  "Two Steps v2": "Two Steps v2",
  "Custom Input": "Custom Input",
  "Extensions": "Extensions",
  "Tour": "Tour",
  "Register Multi-Steps": "Register Multi-Steps",
  "Wizard Examples": "Wizard Examples",
  "Checkout": "Checkout",
  "Create Deal": "Create Deal",
  "Property Listing": "Property Listing",
  "Roles & Permissions": "Roles & Permissions",
  "Roles": "Roles",
  "Simple Table": "Simple Table",
  "Tables": "Tables",
  "Data Table": "Data Table",
  "Permissions": "Permissions",
  "Apps": "Apps",
  "Misc": "Misc",
  "Wizard Pages": "Wizard Pages",
  "Form Wizard": "Form Wizard",
  "Numbered": "Numbered",
  "$vuetify": {
    "badge": "Badge",
    "pagination": {
      "ariaLabel": {
        "root": "root",
        "previous": "previous",
        "next": "next",
        "currentPage": "currentPage",
        "page": "page"
      }
    },
    "input": {
      "clear": "clear",
      "appendAction": "appendAction",
      "prependAction": "prependAction",
      "counterSize": "counterSize"
    },
    "fileInput": {
      "counterSize": "counterSize"
    },
    "rating": {
      "ariaLabel": {
        "item": "item"
      }
    }
  },
  "Home": "Home",
  "Technologies": "Technologies",
  "Profile": "Profile",
  "Settings": "Settings",
  "Applicants": "Applicants",
  "Logout": "Logout",
  "or": "or",
  "cancel": "Cancel",
  "ok": "OK",
  "back-home": "Back to Home",
  "table-header": {
    "id": "ID",
    "title": "TITLE",
    "actions": "AKTIONS"
  },
  "delete-item": {
    "title": "Are you sure you want to delete this item?"
  },
  "edit-item": {
    "title": "Edit Item"
  },
  "pages": {
    "login": {
      "welcome": "Welcome to {title}! 👋🏻",
      "signin-text": "Please sign-in to your account and start the adventure",
      "new-on-platform": "New on our platform?",
      "create-account": "Create an account"
    },
    "not-authorized": {
      "title": "You are not authorized! 🔐",
      "text": "You don't have permission to access this page."
    },
    "getting-logged-in": {
      "title": "Your login process is underway.",
      "login-error": {
        "title": "Login Failed",
        "text": "Please double-check your credentials and try again. If you continue to experience issues, contact customer support for assistance."
      }
    }
  },
  "error-page": {
    "title": "Page Not Found ⚠️",
    "text": "We couldn't find the page you are looking for."
  },
  "roles": {
    "User": "Applicant",
    "Admin": "Admin",
    "SWX Admin": "SWX Admin",
    "SWX (Pending)": "SWX (Pending)"
  }
}
