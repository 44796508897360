import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import { isUserLoggedIn } from './utils'
import routes from '~pages'
import { canNavigate } from '@layouts/plugins/casl'

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        ...setupLayouts(routes),
    ],
})

router.beforeEach(async to => {
    const isLoggedIn = await isUserLoggedIn()

    console.error('to', to)
    console.error('canNavigate(to)', canNavigate(to))

    // if not logged in, clear local storage
    if (!isLoggedIn)
        localStorage.clear()

    if (to.name === 'getting-logged-in') {
        if (isLoggedIn) {
            if (to.query.to)
                return to.query.to
            else
                return { name: 'index' }
        }
        else {
            return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
        }
    }
    else if (canNavigate(to)) {
        if (to.meta.redirectIfLoggedIn && isLoggedIn)
            return '/'
    }
    else {
        if (isLoggedIn)
            return { name: 'not-authorized' }
        else
            return { name: 'login', query: { to: to.name !== 'index' ? to.fullPath : undefined } }
    }
})

// Docs: https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards

export default router
