import type { UserAbility } from '@/plugins/casl/AppAbility'

export const abilitiesByRole: { [key: string]: UserAbility[] } = {
    // Admin
    'dd947a55-ed4b-40c1-bb7a-20a6160acb01': [
        {
            action: 'read',
            subject: 'Auth',
        },
        {
            action: 'read',
            subject: 'User',
        },
        {
            action: 'manage',
            subject: 'Technologies',
        },
        {
            action: 'manage',
            subject: 'Applicants',
        },

        // {
        //     action: 'update',
        //     subject: 'User',
        // },

        // {
        //     action: 'create',
        //     subject: 'User',
        // },
    ],

    '28451293-d430-4bd9-91e4-115b45c21908': [
        {
            action: 'read',
            subject: 'Auth',
        },
        {
            action: 'read',
            subject: 'User',
        },
        {
            action: 'manage',
            subject: 'Technologies',
        },
        {
            action: 'manage',
            subject: 'Applicants',
        },
    ],
}
