import type { DirectusResponse } from '@directus/sdk'
import { authentication, createDirectus, realtime, rest } from '@directus/sdk'

// import { authentication } from '@directus/sdk/auth'
// import type { DirectusResponse } from '@directus/sdk/rest'
// import { rest } from '@directus/sdk/rest'

import { abilitiesByRole } from '@sw-xperts/plugins/directus-casl'
import type { Schema, User } from '@sw-xperts/types'

import ability from '@/plugins/casl/ability'

// const ability = useAppAbility()

export const client = createDirectus<Schema>(process.env.DIRECTUS_URL)
    .with(rest({ credentials: 'include' }))
    .with(realtime({
        authMode: 'handshake',
        reconnect: {
            retries: 3,
            delay: 1000,
        },
        heartbeat: true,
        debug: true,
    }))
    .with(authentication('session', { credentials: 'include' }))

export const getUser = async (): Promise<DirectusResponse<User>> => {
    try {
        await client.refresh()
    }
    catch (error) {
        console.error('refresh error', error)
    }

    const user = await client.request<DirectusResponse<User>>(
        () => {
            return {
                method: 'GET',
                path: '/users/me',
                params: {
                    fields: [
                        'id',
                        'email',
                        'role',
                        'avatar',
                        'first_name',
                        'last_name',
                        'status',
                    ],
                },
            }
        },
    )

    if (user.role && abilitiesByRole?.[user.role]) {
        const userAbilities = abilitiesByRole?.[user.role]

        localStorage.setItem('userAbilities', JSON.stringify(userAbilities))

        ability.update(userAbilities)
    }

    return user
}

// export const userData = ref<User | null>(null);
export const userDataStore = {
    get: async () => {
        const user = ref<User | null>(null)

        user.value = await getUser().catch(err => {
            console.error('getUser Error', err)
        })

        return user
    },
    set: (value = null) => {
        if (value)
            localStorage.setItem('userData', JSON.stringify(value))

        else if (userData.value)
            localStorage.setItem('userData', JSON.stringify(userData.value))
    },
}

export const userData = ref<User | null>(null)
