import { breakpointsVuetify } from '@vueuse/core'

import { VIcon } from 'vuetify/components/VIcon'

// ❗ Logo SVG must be imported with ?raw suffix
import logo from '@images/logo.svg?raw'

import { defineThemeConfig } from '@core'
import { RouteTransitions, Skins } from '@core/enums'
import { AppContentLayoutNav, ContentWidth, FooterType, NavbarType } from '@layouts/enums'

export const { themeConfig, layoutConfig } = defineThemeConfig({
    app: {
        title: 'sw-xperts',

        // ❗ if you have SVG logo and want it to adapt according to theme color, you have to apply color as `color: rgb(var(--v-global-theme-primary))`
        logo: h('div', { innerHTML: logo, style: 'line-height:0; color: rgb(var(--v-global-theme-primary))' }),
        contentWidth: ContentWidth.Boxed,
        contentLayoutNav: AppContentLayoutNav.Vertical,
        overlayNavFromBreakpoint: breakpointsVuetify.md + 16, // 16 for scrollbar. Docs: https://next.vuetifyjs.com/en/features/display-and-platform/
        enableI18n: true,
        theme: 'system',
        isRtl: false,
        skin: Skins.Default,
        routeTransition: RouteTransitions.Fade,
        iconRenderer: VIcon,
    },
    navbar: {
        type: NavbarType.Sticky,
        navbarBlur: true,
    },
    footer: { type: FooterType.Hidden },
    verticalNav: {
        isVerticalNavCollapsed: true,
        defaultNavItemIconProps: { icon: 'mdi-circle-outline' },
        isVerticalNavSemiDark: false,
    },
    horizontalNav: {
        type: 'sticky',
        transition: 'slide-y-reverse-transition',
    },
    icons: {
        chevronDown: { icon: 'mdi-chevron-down' },
        chevronRight: { icon: 'mdi-chevron-right' },
        close: { icon: 'mdi-close' },
        verticalNavPinned: { icon: 'mdi-radiobox-marked' },
        verticalNavUnPinned: { icon: 'mdi-radiobox-blank' },
        sectionTitlePlaceholder: { icon: 'mdi-minus' },
    },
})
